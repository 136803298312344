import { type FC, ReactElement, useEffect, useState } from 'react';

import { Button, Grid, Box as MuiBox, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'next-intl';

import { RouterLink } from '@components/router-link';

import { paths } from '@paths/index';

import { Features } from './features';
import Main from './main';

export const NewHero = ({ isMobile }: { isMobile: boolean }): ReactElement => {
  const t = useTranslations('Landing');
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  return (
    <>
      <Grid container sx={{ zIndex: 100, position: 'relative' }}>
        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <TextContainer>
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                color: '#F2F2F2',
                fontSize: '4rem !important',
                ['@media (max-width:800px)']: { fontSize: '2.5rem !important' },
              }}
            >
              {t.rich('headline', {
                entry: (chunks) => (
                  <Typography component="span" color="#FD3" variant="inherit">
                    {chunks}
                  </Typography>
                ),
                for: (chunks) => (
                  <Typography component="span" color="#14D990" variant="inherit">
                    {chunks}
                  </Typography>
                ),
                clients: (chunks) => (
                  <Typography component="span" color="#14D990" variant="inherit">
                    {chunks}
                  </Typography>
                ),
              })}
            </Typography>
            <Grid item xs={12} md={10}>
              <Typography
                color="text.secondary"
                sx={{
                  color: '#F2F2F2',
                  fontSize: 16,
                  fontWeight: 300,
                  my: 6,
                }}
              >
                {t('subline')}
              </Typography>
            </Grid>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Button
                component="a"
                href={'https://tally.so/r/nraoXo'}
                variant="contained"
                target="_blank"
                sx={{
                  width: '192px',
                  height: '48px',
                  ['@media (max-width:800px)']: { width: '100%' },
                  color: '#021111',
                }}
              >
                {t('join-waitlist')}
              </Button>
              <Button
                variant="outlined"
                component={RouterLink}
                href={paths.contact}
                sx={{
                  width: '192px',
                  height: '48px',
                  ['@media (max-width:800px)']: { display: 'none', color: '#F2F2F2' },
                  color: '#F2F2F2',
                  marginLeft: '32px !important',
                  borderColor: '#F2F2F2',
                }}
              >
                {t('more')}
              </Button>
            </Stack>
          </TextContainer>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={9}
        sx={{
          marginTop: 5,
          zIndex: 100,
          position: 'relative',
          paddingTop: '100px',
          ['@media (max-width:800px)']: {
            paddingTop: '0px',
            flexDirection: 'row',
          },
        }}
      >
        <Features />
      </Grid>
      {!isMobile && isVisible && (
        <ImageContainer>
          <Main />
        </ImageContainer>
      )}
    </>
  );
};

const Box = styled(MuiBox)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  paddingTop: '60px',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '20px',
    ['::before']: {
      width: '500px',
      height: '500px',

      left: '-185px',
      top: '-146px',
    },
  },
}));

const TextContainer = styled(MuiBox)(({ theme }) => ({
  maxWidth: 'md',
  paddingTop: 20,
  [theme.breakpoints.down('sm')]: {
    paddingTop: '20px',
  },
}));

const ImageContainer = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  marginBottom: '0px',
  paddingBottom: '0px',
  maxWidth: '100%',
}));
