import { useMediaQuery } from '@mui/material';

import { Seo } from '@components/seo';

import { Layout as AuthLayout } from '@layouts/landing/layout';
import { Layout as MarketingLayout } from '@layouts/marketing';

import { usePageView } from '@hooks/use-page-view';

import { NGlobal } from '@typings/global';
import type { Page as PageType } from '@typings/page';

import { PostNewsletter } from '@sections/home/home-newsletter';
import { NewHero } from '@sections/new-landing/landing';

const Page: PageType = () => {
  usePageView();
  const isMobile = useMediaQuery((theme: NGlobal.TObject) => theme.breakpoints.down('sm'));

  return (
    <div style={{ height: '100%' }}>
      <Seo />
      <AuthLayout isMobile={isMobile}>
        <NewHero isMobile={isMobile} />
      </AuthLayout>
    </div>
  );
};

Page.getLayout = (page: any) => <MarketingLayout>{page}</MarketingLayout>;

export default Page;
export async function getStaticProps({ locale }: NGlobal.TLocale) {
  return {
    props: {
      messages: (await import(`../translations/landing/${locale}.json`)).default,
    },
  };
}
