import { type FC, type ReactNode, useContext } from 'react';

import { Box, Button, Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'next-intl';

import { Logo } from '@components/Logo';
import { RouterLink } from '@components/router-link';

import { paths } from '@paths/index';

import { SettingsContext } from '@contexts/settings-context';

import MainMobile from '@sections/new-landing/main-mobile';

const TOP_NAV_HEIGHT = 64;

const CIRCLE_RADIUS_BG = '887px';
const CIRCLE_LINE = '752.495px';
const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  textAlign: 'right',
  justifyContent: 'flex-end', // Wyrównaj zawartość do prawej strony
}));

const LayoutRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  minHeight: '100vh',
  backgroundSize: 'cover', // Aby tło zajmowało całą przestrzeń kontenera

  ['::before']: {
    content: '""',
    position: 'absolute',
    width: CIRCLE_RADIUS_BG,
    height: CIRCLE_RADIUS_BG,
    maxWidth: CIRCLE_RADIUS_BG,
    maxHeight: CIRCLE_RADIUS_BG,
    borderRadius: CIRCLE_RADIUS_BG,
    background:
      'radial-gradient(50% 50.00% at 50% 50.00%, rgba(8, 84, 84, 0.30) 0%, rgba(8, 84, 84, 0.00) 100%)',
    left: '-16%',
    top: '-40%',
    ['@media (max-width:800px)']: {
      width: '500px',
      height: '500px',
      maxWidth: '500px',
      maxHeight: '500px',
      left: '-150px',
      top: '-150px',
    },
  },
  ['::after']: {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: CIRCLE_LINE,
    height: CIRCLE_LINE,
    borderRadius: CIRCLE_LINE,
    borderColor: 'rgba(255, 255, 255, 0.05)',
    borderWidth: '2px',
    borderStyle: 'solid',
    left: '-10%',
    top: '-33%',
    ['@media (max-width:800px)']: {
      width: '400px',
      height: '400px',
      maxWidth: '500px',
      maxHeight: '500px',
      left: '-100px',
      top: '-50px',
    },
  },
  backgroundColor: '#081A1A',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
}));

interface LayoutProps {
  children: ReactNode;
  isMobile: boolean;
}

export const Layout: FC<LayoutProps> = ({ children, isMobile }) => {
  const t = useTranslations('Landing');

  return (
    <LayoutRoot>
      <Box
        sx={{
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container maxWidth="xl">
          <Stack
            direction="row"
            sx={{ height: TOP_NAV_HEIGHT, marginTop: 5, justifyContent: 'space-between' }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
            >
              <Box
                sx={{
                  height: 26,
                  width: 131,
                }}
              >
                <Logo inverse />
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  href={paths.contact}
                  sx={{
                    ['@media (min-width:800px)']: { display: 'none' }, // Wyświetlaj na urządzeniach o szerokości ekranu xs i ukrywaj na md i większych
                    // width: '192px',
                    // height: '48px',
                    color: '#F2F2F2',
                    // marginLeft: '32px !important',
                    borderColor: '#F2F2F2',
                  }}
                >
                  {t('more')}
                </Button>
                <Button
                  component="a"
                  href={'https://tally.so/r/nraoXo'}
                  variant="contained"
                  target="_blank"
                  sx={{
                    ['@media (max-width:800px)']: { display: 'none' },
                    color: '#021111',
                  }}
                >
                  {t('join-waitlist')}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            py: {
              xs: '60px',
              md: '100px',
            },
            paddingBottom: '0px !important',
          }}
        >
          {children}
        </Container>
      </Box>
      {isMobile && (
        <ImageContainer>
          <MainMobile />
        </ImageContainer>
      )}
    </LayoutRoot>
  );
};
