import type { FC } from 'react';

import Head from 'next/head';

interface SeoProps {
  title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
  const { title } = props;

  const fullTitle = title ? title + ' | Moneya Pay' : 'Moneya Pay';

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta name="description" content={'Effortless entry to web3, for you and your clients'} />
      <meta charSet="utf-8" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta
        property="og:description"
        content={'Effortless entry to web3, for you and your clients'}
      />
      <meta property="og:image" content="https://moneya.org/assets/moneya.jpeg" />
      <meta name="twitter:title" content={fullTitle} />
      <meta
        name="twitter:description"
        content={'Effortless entry to web3, for you and your clients'}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://moneya.org/assets/moneya.jpeg" />
    </Head>
  );
};
