import type { FC } from 'react';

import { Avatar, Unstable_Grid2 as Grid, Box as MuiBox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'next-intl';

interface Review {
  title: string;
  message: string;
  image: string;
}

export const Features: FC = () => {
  const t = useTranslations('Landing');

  const reviews: Review[] = [
    {
      title: t('features.one.title'),
      message: t('features.one.message'),
      image: '/assets/landing/features01.svg',
    },
    {
      title: t('features.two.title'),
      message: t('features.two.message'),
      image: '/assets/landing/features02.svg',
    },
    {
      title: t('features.three.title'),
      message: t('features.three.message'),
      image: '/assets/landing/features03.svg',
    },
  ];

  return reviews.map((review, i) => (
    <Card key={i}>
      <CardContent>
        <ReviewAvatar src={review.image} alt={review.title} />
        <Typography
          sx={{
            fontSize: '12px',
            flexGrow: 1,
            color: '#F2F2F2',
            ['@media (min-width: 800px)']: { marginTop: '16px !important' },
            ['@media (max-width: 800px)']: { fontSize: '12px' },
          }}
        >
          {review.message}
        </Typography>
      </CardContent>
    </Card>
  ));
};

const Card = styled(MuiBox)(({ theme }) => ({
  height: '100%',
  maxWidth: '220px !important',
  marginRight: '48px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '250px !important',
  },
}));

const CardContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '220px !important',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    minWidth: '300px !important',
    paddingTop: '20px',
    flexDirection: 'row',
    gap: '20px',
  },
}));

const ReviewAvatar = styled(Avatar)(({ theme }) => ({
  displayf: 'flex',
  height: 40,
  borderRadius: 0,
  backgroundColor: 'transparent',
  backgroundSize: 'contain',
  backgroundPosition: 'top',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    height: 30,
  },
}));
